import React from 'react';
import styled from 'styled-components';
import {message} from 'antd';
import {getOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import {
  getUserStateLabel,
  getUserSubstateLabel,
  getUserInformToLabel,
} from '../../Utils/UserUtil';
import {formatDateStr} from '../../Utils/TimeUtil';
import {App} from 'styled-icons/bootstrap';

function AdminTest(props) {
  return <Wrapper></Wrapper>;
}

const Wrapper = styled.div`
  padding: 20px;

  & > section {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export default AdminTest;
